import React from "react";

import HelpCenterItem from "../../containers/HelpCenter/HelpCenterItem";

const HelpCenterArticle = ({ path }) => {
  return (
    <HelpCenterItem path={path}>
      <div>
        <div>
          <p>
            <b>Apple:</b>
          </p>
        </div>
        <ul>
          <li>Go to Settings on your Apple device</li>
          <li>Tap “iTunes & App Store”</li>
          <li>Tap your “Apple ID”</li>
          <li>
            Make sure you are viewing the same Apple ID that you initially made
            the purchase from
          </li>
          <li>Tap “Subscriptions”</li>
          <li>Select active Tobo subscription and cancel</li>
        </ul>
        <div>
          <p>
            <b>Google:</b>
          </p>
        </div>
        <ul>
          <li>Go to Google Play Store</li>
          <li>
            Make sure you are viewing the same Google account that you initially
            made the purchase from
          </li>
          <li>Tap “Subscriptions”</li>
          <li>Select active Tobo subscription and cancel</li>
        </ul>
      </div>
    </HelpCenterItem>
  );
};

export default HelpCenterArticle;
